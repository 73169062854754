<template>
  <div class="left mb-20">
    <div class="top-div">
      <section class="title flex fjsb fac">
        <div class="bg flex fjsa fac" style="justify-content:flex-start">
          <img class="ml-20" src="@/assets/img/drawer/smile.png" alt="">
          <span class="ml-20">{{dataInfo.nickName}}</span>
        </div>
        <div>{{dataInfo.planName}}</div>
        <div class="top-charts-icon">
          <img src="@/assets/img/drawer/hg.png" alt="" class="right-img mr-10 mt-10 cursor-default"
            v-if="chartType === 'gauge'" @click="chartType = 'bar'">
          <img src="@/assets/img/drawer/fc.png" alt="" class="right-img mr-10 mt-10 cursor-default"
            v-if="chartType === 'bar'" @click="chartType = 'gauge'">
        </div>
      </section>
    </div>
    <div class="bottom-dev">
      <div class="l-left">
        <table class="ml-20 mt-20">
          <tr>
            <th>动作次数</th>
            <th>运动时间</th>
            <th v-if="chartType === 'gauge'">平均功率</th>
            <th v-if="chartType === 'gauge'">平均速度</th>
          </tr>
          <tr>
            <td><span>{{dataInfo.currentCount}}</span> 次</td>
            <td><span>{{dataInfo.takeUpTime}}</span> </td>
            <td v-if="chartType === 'gauge'"><span>{{dataInfo.powerAvg}}</span> W</td>
            <td v-if="chartType === 'gauge'"><span>{{dataInfo.speedAvg}}</span> m/s</td>
          </tr>
          <tr>
            <th>能量消耗</th>
            <th>平均RFD</th>
            <th v-if="chartType === 'gauge'">峰值功率</th>
            <th v-if="chartType === 'gauge'">峰值速度</th>
          </tr>
          <tr>
            <td><span>{{dataInfo.energe }}</span> cal</td>
            <td><span>{{dataInfo.rfd}}</span> N/S</td>
            <td v-if="chartType === 'gauge'"><span>{{dataInfo.powerMax}}</span> W</td>
            <td v-if="chartType === 'gauge'"><span>{{dataInfo.speedMax}}</span> m/s</td>
          </tr>

        </table>
      </div>
      <div class="l-right ml-50 l-right-gague" v-if="chartType === 'gauge'">
        <img src="@/assets/img/drawer/cr.png" alt="">
        <img src="@/assets/img/drawer/db.png" alt="">
        <gauge-chart :name="'home-drawer-' + index" :refreshFlagDrawer="refreshFlagDrawer"
          :current-count="dataInfo.currentCount" :total-count="dataInfo.totalCount"></gauge-chart>
      </div>
      <div class="l-right bar" style="width: 65%;" v-if="chartType === 'bar'">
        <el-radio-group v-model="radio" size="mini" class="mb-10" @change="radioGroupChange">
          <el-radio-button label="平均功率"></el-radio-button>
          <el-radio-button label="峰值功率"></el-radio-button>
          <el-radio-button label="平均速度"></el-radio-button>
          <el-radio-button label="峰值速度"></el-radio-button>
          <el-radio-button label="RFD"></el-radio-button>
        </el-radio-group>
        <bar-chart :name="'home-bar-' + index" :refreshFlagDrawer="refreshFlagDrawer" :countList="dataInfo.countList"
          :inList="inList" :outList="outList"></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
  import GaugeChart from './gauge-chart2'
  import BarChart from './bar-chart'
  export default {
    name: 'home-drawer-detail',
    components: {
      BarChart,
      GaugeChart
    },
    props: {
      userId: {
        type: Number,
        default: null
      },
      index: {
        type: Number,
        default: 0
      },
      refreshFlag: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        radio: '平均功率',
        chartType: 'gauge',
        refreshFlagDrawer: false,
        dataInfo: {},
        inList: [],
        outList: []
      }
    },
    watch: {
      refreshFlag: {
        immediate: false,
        handler: function(val) {
          console.log("refreshFlag", val);
          this.queryRealtimeDetail()
        }
      }
    },
    mounted() {
      //console.log('refreshFlagCard-created')
      this.queryRealtimeDetail()
    },
    methods: {
      queryRealtimeDetail: function() {
        this.$api.realtimeDetail({
          "userId": this.userId
        }).then(res => {
          if (res.success) {
            this.dataInfo = res.data;
            this.updateBarData(this.radio)
            this.refreshFlagDrawer = !this.refreshFlagDrawer;
          }
        })
      },
      updateBarData: function(val) {
        if (val === '平均功率') {
          this.inList = this.dataInfo.powerAvgInList;
          this.outList = this.dataInfo.powerAvgOutList;
        } else if (val === '峰值功率') {
          this.inList = this.dataInfo.powerPeakInList;
          this.outList = this.dataInfo.powerPeakOutList;
        } else if (val === '平均速度') {
          this.inList = this.dataInfo.speedAvgInList;
          this.outList = this.dataInfo.speedAvgOutList;
        } else if (val === '峰值速度') {
          this.inList = this.dataInfo.speedPeakInList;
          this.outList = this.dataInfo.speedPeakOutList;
        } else if (val === 'RFD') {
          this.inList = this.dataInfo.rfdInList;
          this.outList = this.dataInfo.rfdOutList;
        }
        //console.log('radioGroupChange',val)
      },
      radioGroupChange: function(val) {
        this.updateBarData(val);
        this.refreshFlagDrawer = !this.refreshFlagDrawer;
      }
    }

  }
</script>

<style scoped lang="less">
  .cursor-default {
    cursor: pointer
  }

  .left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .right-img {
      float: right;
      width: 27px;
      height: 27px;
    }
  }



  .top-div {
    display: flex;
    justify-content: space-between;

    .title {
      width: 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF !important;
      line-height: 10px;

      .bg {
        width: 230px;
        height: 55px;
        background: #FFA200;
        border-radius: 10px 0px 10px 0px;
        font-size: 33px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 10px;

        img {
          width: 33px;
          height: 33px;
        }
      }

      .top-charts-icon{

      }
    }
  }

  .bottom-dev {}

  .l-left {
    float: left;

    .title {
      max-width: 444px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF !important;
      line-height: 40px;

      .bg {
        width: 192px;
        height: 55px;
        background: #FFA200;
        border-radius: 10px 0px 10px 0px;
        font-size: 33px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;

        img {
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  .l-right-gague {
    top: -40px;
  }

  .l-right {
    min-width: 220px;
    height: 220px;
    float: left;
    position: relative;
    //width: 100%;


    img:nth-child(1) {
      width: 220px;
      height: 220px;
      position: absolute;
      top: 20px;
      right: 0;
    }

    img:nth-child(2) {
      width: 195px;
      height: 195px;
      position: absolute;
      top: 32px;
      right: 12px;
    }

    .home-card-echarts {
      position: absolute;
      top: 44px;
      right: 25px;
      width: 170px;
      height: 170px;
    }
  }

  .bar {
    min-width: 430px;
  }

  table {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 33px;

    tr {

      th,
      td {
        width: 120px;
        text-align: left;

        span {
          font-size: 24px;
          font-weight: bold;
          color: #FFA200;
          line-height: 33px;
        }
      }
    }
  }

  .progress {
    span {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-style: italic;
      color: #FFA200;
      line-height: 26px;
    }

    width: 444px;

    /deep/.el-progress-bar__inner {
      background: #FFA200;
    }
  }



  .el-radio-group {
    width: 100%;

    label.is-active {
      /deep/.el-radio-button__inner {
        background: #FFA200;
        border-color: transparent;
        box-shadow: 0 0 0 0;
      }
    }

    label {
      min-width: 75px;

      /deep/.el-radio-button__inner {
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 8px;
        background: transparent;
        padding: 7px 11px !important;
        border-color: transparent;
        box-shadow: 0 0 0 0;

        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }
</style>
