<template>
  <div :id="name"
       class="home-bar-echarts"></div>
</template>

<script>
export default {
  name: 'bar-chart',
  data(){
    return {
      myChart:null,
      options:{
          dataZoom: [{
              show: false, //是否显示下方滚动条
              realtime: true,
              startValue: 0, //显示数据开始位置
              endValue: 8 //显示数据结束位置
            },
            {
              type: 'inside',
              zoomLock:true,
              realtime: true
            }
          ],
          label: {
            show: true,
            position: 'top',
            color: '#FFF',
            fontSize: 8
          },
          grid: {
            show:false,
            left: '0',
            right: '0',
            top:"10%",
            height: '85%',
            containLabel: true
          },
          xAxis: [{
            offset: 5,
            type: 'category',
            data: [1, 2, 3, 4, 5, 6],
            position: 'bottom',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            }
          }],
          yAxis: [{
            type: 'value',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            splitLine:{
              show:false
            }
          }],
          series: [{
            data: [],
            type: 'bar',
            barWidth:10,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#feb812' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#ff7e42' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false#909090 #686868
            },
            itemStyle: {
              borderRadius: [50, 50, 0, 0]
            }

          }, {
            data: [],
            type: 'bar',
            barWidth:10,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#909090' // 0% 处的颜色
              }, {
                offset: 1,
                color: '#686868' // 100% 处的颜色
              }],
              globalCoord: false // 缺省为 false
            },
            itemStyle: {
              borderRadius: [50, 50, 0, 0]
            }
          }]
        }
    }
  },
  props: {
    name: {
      type: String
    },
    countList:{
      type:Array,
      default:function(){
        return [];
      }
    },
    inList:{
      type:Array,
      default:function(){
        return [];
      }
    },
    outList:{
      type:Array,
      default:function(){
        return [];
      }
    },
    refreshFlagDrawer: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    refreshFlagDrawer: {
      immediate: false,
      handler: function(val) {
        console.log("refreshFlagDrawer-bar", val);
        this.buildData();
      }
    }
  },
  mounted() {
    this.buildData();
  },
  methods:{
    buildData:function(){
      if(this.myChart == null){
        this.myChart = this.$echarts.init(document.getElementById(this.name));
      }
      let options = JSON.parse(JSON.stringify(this.options));
      if(this.countList.length > options.xAxis[0].data.length){
        options.xAxis[0].data = this.countList;
        let countTemp = this.countList[this.countList.length - 1];
        options.dataZoom[0].startValue = countTemp - 8;
        options.dataZoom[0].endValue = countTemp;
      }
      options.series[0].data = this.inList;
      options.series[1].data = this.outList;
      this.myChart.setOption(options)
    }
  }
}
</script>

<style scoped lang="less">
.home-bar-echarts {
    width: 100%;
    height: 80%;
  }
</style>
