<template>
  <div class="body flex mb-20 home-drawer fw">
    <template v-for="(item, index) of chooseUserList">
      <HomeDrawerDetail :userId="item" :index="index" :refreshFlag="refreshFlag" class="ml-30"></HomeDrawerDetail>
    </template>
  </div>
</template>

<script>
  import HomeDrawerDetail from './home-drawer-detail'
  export default {
    name: 'home-drawer',
    components: {
      HomeDrawerDetail
    },
    props: {
      chooseUserList: {
        type: Array,
        default: []
      },
      drawerFlag: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      drawerFlag: {
        immediate: true,
        handler: function(val) {
          console.log("drawerFlag", val);
          if (val) {
            this.interval = setInterval(function(_this) {
              _this.refreshFlag = !_this.refreshFlag;
            }, 3000, this)
          } else {
            clearInterval(this.interval)
          }
        }
      }
    },
    data() {
      return {
        interval: null,
        refreshFlag: false
      }
    },
    methods: {

    }

  }
</script>

<style scoped lang="less">
  .left {

    .right-img {
      float: right;
      width: 33px;
      height: 27px;
    }
  }

  .right {
    .right-img {
      float: right;
      width: 34px;
      height: 34px;
    }

    .l-right {
      width: 300px;
      height: 200px;
    }
  }

  .l-left {
    float: left;

    .title {
      width: 444px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF !important;
      line-height: 40px;

      .bg {
        width: 192px;
        height: 55px;
        background: #FFA200;
        border-radius: 10px 0px 10px 0px;
        font-size: 33px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;

        img {
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  .l-right {
    width: 220px;
    height: 220px;
    float: left;
    position: relative;

    img:nth-child(1) {
      width: 220px;
      height: 220px;
      position: absolute;
      top: 20px;
      right: 0;
    }

    img:nth-child(2) {
      width: 195px;
      height: 195px;
      position: absolute;
      top: 32px;
      right: 12px;
    }

    .home-card-echarts {
      position: absolute;
      top: 44px;
      right: 25px;
      width: 170px;
      height: 170px;
    }
  }

  table {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 33px;

    tr {

      th,
      td {
        width: 120px;
        text-align: left;

        span {
          font-size: 24px;
          font-weight: bold;
          color: #FFA200;
          line-height: 33px;
        }
      }
    }
  }

  .progress {
    span {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-style: italic;
      color: #FFA200;
      line-height: 26px;
    }

    width: 444px;

    /deep/.el-progress-bar__inner {
      background: #FFA200;
    }
  }

  .home-bar-echarts {
    width: 300px;
    height: 150px;
  }

  .el-radio-group {
    label.is-active {
      /deep/.el-radio-button__inner {
        background: #FFA200;
        border-color: transparent;
        box-shadow: 0 0 0 0;
      }
    }

    label {
      width: 75px;

      /deep/.el-radio-button__inner {
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        line-height: 8px;
        background: transparent;
        padding: 7px 11px !important;
        border-color: transparent;
        box-shadow: 0 0 0 0;

        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }
</style>
