<template>
  <div class="flex home-card fd fac fs-16 cursor-default" @click="chooseCard(item.userId)">
    <div class="top flex fjsa fac"  style="justify-content:flex-start"
         :class="chooseFlag?'active':''">
         <div class="ml-6">
           <gauge-chart :name="name" :refreshFlagCard="refreshFlagCard"
         :current-count="item.currentCount"
         :total-count="item.totalCount"></gauge-chart></div>

      <ul class="mr-18 ml-20">
        <li class="mb-4">
          <span>昵称：</span>
          <span>{{item.nickname}}</span>
        </li>
        <li class="mb-4">
          <span>模式：</span>
          <span>{{item.planName}}</span>
        </li>
        <li>
          <span>用时：</span>
          <span>{{item.takeUpTime}}</span>
        </li>
      </ul>
    </div>
    <!-- <div class="bottom-panel" @click="chooseCard">
      <img src="@/assets/img/home/light.png"
           alt="按钮" class="bottom"
           v-show="chooseFlag">
      <img src="@/assets/img/home/off.png" alt="按钮"
           class="bottom" v-show="!chooseFlag">
      <span>詹姆斯</span>
    </div> -->
  </div>
</template>

<script>
import GaugeChart from './gauge-chart'
export default {
  name: 'home-card',
  components: { GaugeChart },
  data () {
    return {
      chooseFlag: false
    }
  },
  props: {
    item:{
      type:Object,
      default:{}
    },
    name: {
      type: String,
      default: ''
    },
    refreshFlagCard: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    chooseCard(userId) {
      console.log("this.chooseFlag", this.chooseFlag, userId)
      if(this.chooseFlag){
        //取消选中
        this.chooseFlag = false;
        this.$emit('cancelUser', userId)
      } else {
        //选中用户
        this.chooseFlag = true;
        this.$emit('chooseUser', userId)
      }
    }
  }
}
</script>

<style scoped lang="less">
.home-card {
  padding: 0.225rem;
  &-echarts {
    width: 88px;
    height: 88px;
  }

  .top {
    width: 355px;
    height: 103px;
    background: #333333;
    border-radius: 51px;
    color: #FFFFFF;
    border-bottom: 2px solid gray;
    border-top: 2px solid gray;
    &.active{
      border-bottom: 2px solid #af8318;
      border-top: 2px solid #af8318;
    }
  }

  .bottom-panel{
    cursor: pointer;
    width: 213px;
    height: 130px;
    position: relative;
    &:hover {
      opacity: 0.9;
      transition: opacity 0.15s linear;
    }
    span{
      position: absolute;
      width: 65px;
      height: 21px;
      font-size: 21px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 25px;
      bottom: 25px;
      left: calc(50% - 32px);
    }
  }
  .bottom {
    width: 213px;
    height: 130px;
    border-radius: 10px;
  }
}
</style>
