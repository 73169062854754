<template>
  <div :id="name" class="home-card-echarts"></div>
</template>

<script>
  export default {
    name: 'gauge-chart2',
    data() {
      return {
        myChart: null,
        series: [{
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          radius: '100%',
          pointer: {
            show: false
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              color: {
                colorStops: [{
                  offset: 0,
                  color: '#ffc944' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#ff7d43' // 100% 处的颜色
                }]
              }
            }
          },
          axisLine: {
            lineStyle: {
              width: 10,
              opacity: 0.2
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          data: [{
            value: 0,
            name: '目标百分比',
            title: {
              offsetCenter: ['0%', '-25%']
            },
            detail: {
              offsetCenter: ['0%', '30%']
            }
          }],
          title: {
            fontSize: 16 * this.$store.state.rem / 100,
            color: 'white'
          },
          detail: {
            fontSize: 45 * this.$store.state.rem / 100,
            color: 'white',
            formatter: this.currentCount + "/" + this.totalCount
          }
        }]
      }
    },
    props: {
      name: {
        type: String
      },
      currentCount: {
        type: Number,
        default: 0
      },
      totalCount: {
        type: Number,
        default: 10
      },
      refreshFlagDrawer: {
        type: Boolean,
        default: true
      }
    },
    watch: {
      refreshFlagDrawer: {
        immediate: false,
        handler: function(val) {
          console.log("refreshFlagDrawer-gauge", val);
          this.buildData();
        }
      }
    },
    mounted() {
      console.log('refreshFlagCard-created')
      this.buildData()
    },
    methods: {
      parseRate: function() {
        if (this.currentCount > this.totalCount) {
          return 100;
        }
        return parseInt((this.currentCount / this.totalCount) * 100)
      },
      buildData: function() {
        if (this.myChart == null) {
          this.myChart = this.$echarts.init(document.getElementById(this.name));
        }

        let series = JSON.parse(JSON.stringify(this.series));
        series[0].data[0].value = this.parseRate();
        series[0].detail.formatter = this.currentCount + "/" + this.totalCount;
        this.myChart.setOption({
          series: series
        })
      }
    }
  }
</script>

<style scoped>

</style>
