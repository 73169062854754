<template>
  <el-container>
    <el-header class="flex fjsb fac">
      <section class="logo flex fjsb fac">
        <img src="@/assets/img/home/logo.png" alt="logo">
        <div class="flex fd ml-24">
          <span class="fs-30">运动实时数据管理</span>
          <span class="fs-19">Motion data management</span>
        </div>
      </section>
      <section class="header-right flex fjsb fac">
        <!--        <el-radio-group class="flex fd"
                        v-model="radio">
          <el-radio :label="1" class="mb-10">循环播放</el-radio>
          <el-radio :label="2">固定播放</el-radio>
        </el-radio-group>
        <div class="mr-20">
          <span>26</span>秒/次
        </div>-->
        <div class="flex fjc fac mr-20 cursor-default" @click="gotoDetailPage">
          <img src="@/assets/img/detail/data.png" alt="管理平台" class="real-time mr-6">
          <span>管理平台</span>
        </div>

        <!-- <div class="flex fjc fac mr-20">
          <img src="@/assets/img/home/fc.png" alt="注销" class="logout mr-6">
          <span class="cursor-default">全屏</span>
        </div>
 -->
        <div class="flex fjc fac mr-20 cursor-default" @click="exitLogin">
          <img src="@/assets/img/home/close.png" alt="退出登陆" class="logout mr-6">
          <span>退出登陆</span>
        </div>

        <el-avatar :src="userInfo.fileDynamic.Avatar" class="mr-10">
        </el-avatar>
        <span class="fs-20">{{userInfo.nickname}}</span>
      </section>
    </el-header>
    <el-main class="flex fw fjc fac">
      <template v-for="(item, index) of realtimeBaseList">
        <home-card :key="`home-card-${index}`" :item="item" :refreshFlagCard="refreshFlagCard" :name="`home-card-${index}`"
          :ref="`homeCard${index}`" @chooseUser="chooseUser" @cancelUser="cancelUser"></home-card>
      </template>
      <div class="bottom-btn flex fjc fac">
        <el-link :underline="false" class="fs-20" @click="watchSelect">查看选中的运动员实时数据</el-link>
        <!-- <div class="flex fjsa fac ml-14 mr-14">
          <img src="@/assets/img/home/left.png" alt="left" width="29" height="20">
          <img src="@/assets/img/home/play.png" alt="play" class="ml-10 mr-10" width="46" height="46">
          <img src="@/assets/img/home/right.png" alt="right" width="29" height="20">
        </div>
        <el-link :underline="false" class="fs-20" @click="watchAll">浏览全部</el-link> -->
      </div>
    </el-main>
    <el-drawer :visible.sync="drawer" custom-class="drawer" :direction="direction" :before-close="handleClose">
      <!-- <div slot="title" class="title">
        <i class="el-icon-zoom-in"></i>
        <span>放大/缩小</span>
      </div> -->
      <home-drawer ref="homeDrawerRef" :chooseUserList="chooseUserList" :drawerFlag="drawer"></home-drawer>
    </el-drawer>
  </el-container>
</template>

<script>
  import HomeCard from '../components/home-card'
  import HomeDrawer from '../components/home-drawer'
  import { Message } from 'element-ui'
  export default {
    name: 'home',
    components: {
      HomeDrawer,
      HomeCard
    },
    data() {
      return {
        userInfo: this.$storage.getUserInfo(),
        realtimeBaseList:[],
        chooseUserList:[],
        radio: 1,
        refreshFlagCard:false,
        drawer: false,
        direction: 'btt',
        interval:null
      }
    },
    watch:{
      drawer:{
        immediate: true,
        handler: function(val) {
          console.log("drawer", val);
          if(val){
            clearInterval(this.interval);
          } else {
            this.interval = setInterval(function(_this){
              _this.queryRealtimeBaseList()
            },2000, this)
          }
        }
      }
    },
    created: function() {
      console.log("created-home", this.userInfo);
       this.queryRealtimeBaseList()
    },
    methods: {
      queryRealtimeBaseList: function() {
        this.$api.realtimeBaseList({
          userId: this.userInfo.userId
        }).then(res => {
          if(res.success){
            this.realtimeBaseList = res.data
            this.refreshFlagCard = !this.refreshFlagCard
          }
          console.log("realtimeBaseList", res)
        })
      },
      handleClose() {
        console.log('这里是关闭前的回调')
        this.drawer = false
      },
      exitLogin: function() {
        this.$confirm('您确认退出登陆吗？')
          .then(e => {
            if(this.drawer){
              clearInterval(this.$refs.homeDrawerRef.data.interval);
            }else {
              clearInterval(this.interval);
            }

            this.$storage.exitLogin();
            this.$router.push({
              name: 'login'
            })
          })
          .catch(e => {

          });
      },
      // 浏览全部
      watchAll() {
        this.drawer = true
      },
      //浏览选中
      watchSelect() {
        if(this.chooseUserList == null || this.chooseUserList.length === 0){
          Message({
            message: '请先选中需要查看的运动员',
            type: 'warning',
            duration: 3000
          })
          return;
        }
        this.drawer = true
      },
      chooseUser:function(userId){
        console.log('chooseUser', userId);
        this.chooseUserList.push(userId);
      },
      cancelUser:function(userId){
        console.log('cancelUser', userId);
        let index = this.chooseUserList.indexOf(userId);
        this.chooseUserList.splice(index, 1)
      },
      gotoDetailPage: function() {
        clearInterval(this.interval);
        this.$router.push({
          name: 'detail'
        })
      }
    }
  }
</script>
<style scoped lang="less">
  .cursor-default {
    cursor: pointer
  }

  .el-container {
    width: 100vw;
    height: 100vh;
    background: url("../assets/img/home/background.jpg") 100% 100% no-repeat;
    background-size: 100% 100%;

    .el-header {
      width: 100vw;
      height: 0.9rem !important;
      background: #FFA200;
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .logo {
        img {
          width: 0.57rem;
          height: 0.52rem;
        }

        div {
          span:nth-child(1) {
            font-weight: bold;
          }

          span:nth-child(2) {
            font-weight: 300;
            letter-spacing: 0.02rem;
          }
        }
      }

      .header-right {
        img {
          height: 0.28rem;
          width: 0.28rem;
        }

        .is-checked /deep/ .el-radio__inner {
          border-color: #000000;
          background: #000000;
        }

        .is-checked /deep/ .el-radio__label {
          color: #000000;
        }

        .el-avatar {
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }

    .el-main {
      position: relative;

      .bottom-btn {
        width: 379px;
        height: 60px;
        font-size: 1.5rem;
        background: #FFA200;
        border-radius: 19px;
        position: fixed;
        bottom: 20px;
        left: calc(50% - 190px);
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);

        .el-link {
          color: #FFFFFF;

          &:hover {
            opacity: 0.8;
            transition: opacity 0.15s linear;
          }
        }
      }
    }
  }

  /deep/.drawer {
    width: 95%;
    height: 90% !important;
    margin: 0 auto;
    font-size: 20px;
    background: url('../assets/img/home/background2.jpg') no-repeat 100% 100% rgba(0, 0, 0, 0);
    background-size: 100% 100%;

    .el-drawer__header {
      padding: 0;
      margin: 16px;
    }

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFA200;
      line-height: 29px;
    }

    .body {
      .left,
      .right {
        width: 863px;
        height: 273px;
        border: 1px solid #666666;
        border-radius: 10px;
      }
    }
  }

  /deep/.el-drawer__body {
    overflow: auto;
    /* overflow-x: auto; */
  }

</style>
