import { render, staticRenderFns } from "./gauge-chart2.vue?vue&type=template&id=670571b8&scoped=true&"
import script from "./gauge-chart2.vue?vue&type=script&lang=js&"
export * from "./gauge-chart2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670571b8",
  null
  
)

export default component.exports